import React from 'react'
import { Link } from 'gatsby'
import { Flex, Box, Image, HeaderLink } from '../styles/components'

import logo from '../images/mahesh-bijoutier-logo.png'


const DesktopNav = () => (
  <Flex display={['none', 'flex']} justifyContent="space-between" px={2} flexWrap="wrap">
    <HeaderLink to="/bijoux/">Bijoux</HeaderLink>
    <HeaderLink to="/evolution/">Évolution</HeaderLink>
    <HeaderLink to="/presentation/">Présentation</HeaderLink>
    <HeaderLink to="/expositions/">Exposition</HeaderLink>
    <HeaderLink to="/stages/">Stages</HeaderLink>
    <HeaderLink to="/contact/">Contact</HeaderLink>
  </Flex>
)

const MobileNav = () => (
  <Box display={['block', 'none']}>
    <Flex justifyContent="center" px={[0, 2]} flexWrap="wrap" mb={1}>
      <HeaderLink to="/bijoux/">Bijoux</HeaderLink>
      <HeaderLink to="/evolution/" mx={2}>Évolution</HeaderLink>
      <HeaderLink to="/presentation/">Présentation</HeaderLink>
    </Flex>
    <Flex justifyContent="center" px={[0, 2]} flexWrap="wrap">
      <HeaderLink to="/expositions/">Exposition</HeaderLink>
      <HeaderLink to="/stages/" mx={2}>Stages</HeaderLink>
      <HeaderLink to="/contact/">Contact</HeaderLink>
    </Flex>
  </Box>
)

const Header = ({ siteTitle }) => (
  <React.Fragment>
    <Flex justifyContent="center" flexWrap="wrap" pt={3} mb={2} position="relative">
      <Link to="/">
        <Image src={logo} alt="Mahesh Bijoutier" />
      </Link>
      <Box width={[1, null, 10/12]} mt={[1,2]}>
        <DesktopNav />
        <MobileNav />
      </Box>
    </Flex>
  </React.Fragment>
)

export default Header
