import React from 'react'
import styled from 'styled-components'
import { Container, Flex, Box, P, Br, NavLink, BodyLink } from '../styles/components'
import { themeGet } from '@styled-system/theme-get'
import { display } from 'styled-system'

import FondFooter from './fondFooter'

const FooterMainLink = styled(NavLink)`
  font-size: 2rem;
`
const FooterSecondLink = styled(NavLink)`
  ${display};
  font-size: ${themeGet('fontSizes.0','1rem')};
  font-family: ${themeGet('fonts.open', 'Open Sans')};
  font-weight: ${themeGet('fontWeights.regular', 'normal')};
`
FooterSecondLink.defaultProps = {...{...FooterSecondLink.defaultProps, ...{
  display:['inline-block', null, 'block'],
  mr: [1,null,0]
}}}

const FooterBox = ({children}) => <Box width={[1, null, 'auto']}>{children}</Box>;

const Footer = ({ siteTitle }) => (
  <FondFooter>
    <Box color="black" pt={2} pb={3}>
      <Container>
        <Flex justifyContent="space-between" flexWrap="wrap" px={2}>
          <FooterBox>
            <FooterMainLink to="/bijoux/">Bijoux</FooterMainLink>
            <Box mt={[0,null, 1]}>
              <FooterSecondLink to="/bijoux/pendentifs/">Pendentifs</FooterSecondLink>
              <FooterSecondLink to="/bijoux/boucles-d-oreilles/">Boucles d'oreilles</FooterSecondLink>
              <FooterSecondLink to="/bijoux/bagues/">Bagues</FooterSecondLink>
            </Box>
          </FooterBox>
          <FooterBox>
            <FooterMainLink to="/evolution/">Évolution</FooterMainLink>
            <Box mt={[0,null, 1]}>
              <FooterSecondLink to="/evolution/alliances/">Alliances</FooterSecondLink>
              <FooterSecondLink to="/evolution/transformations/">Transformations</FooterSecondLink>
              <FooterSecondLink to="/evolution/reparations/">Réparations</FooterSecondLink>
              <FooterSecondLink to="/evolution/mise-de-grandeur/">Mises de grandeur</FooterSecondLink>
              <FooterSecondLink to="/evolution/desalliances/">Désalliances</FooterSecondLink>
            </Box>
          </FooterBox>
          <FooterBox>
            <FooterMainLink to="/presentation/">Présentation</FooterMainLink>
          </FooterBox>
          <FooterBox>
            <FooterMainLink to="/expositions/">Exposition</FooterMainLink>
          </FooterBox>
          <FooterBox>
            <FooterMainLink to="/stages/">Stages</FooterMainLink>
            <Box mt={[0,null, 1]}>
              <FooterSecondLink to="/stages/temoignages/">Témoignages</FooterSecondLink>
            </Box>
          </FooterBox>
          <FooterBox>
            <FooterMainLink to="/contact/">Contact</FooterMainLink>
            <Box mt={[0,null,1]}>
              <P mt={[0,null,"1.3rem"]} fontSize={0}>
                Mahesh bijoutier <Br display={['none', null, 'block']} />
                1880 Fenalet sur Bex (Vaud) <Br display={['none', null, 'block']} />
                +41 (0)77 407 21 51
              </P>
            </Box>
          </FooterBox>
        </Flex>
        <Flex justifyContent="center" flexWrap="wrap" px={2} mt={2}>
          <P textAlign="center">
            Ce site vous plaît? Il a été pensé et développé <Br display={['none', 'block']} />
            en étroite collaboration avec Mahesh par <BodyLink as="a" href="mailto:yassin@matiere-vive.ch">matière vive</BodyLink></P>
        </Flex>
      </Container>
    </Box>
  </FondFooter>
)

export default Footer
