import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

const StyledFooterFond = styled.div`
  background-size: cover;
  background-color: rgba(200,200,200,1);
  background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})`: 'none'};
`

const FondFooter = ({children}) => {
  const data = useStaticQuery(graphql`
    query {
      fond: file (name:{eq:"fond-bas"}) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }`
  )
  const imageData = data.fond.childImageSharp.gatsbyImageData;
  return (
    <StyledFooterFond backgroundImage={imageData.images.fallback.src}>{children}</StyledFooterFond>
  )
}

export default FondFooter;

// fluid(maxWidth: 1200, quality:80) {
//   ...GatsbyImageSharpFluid
// }