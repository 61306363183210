import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from '../styles/theme.js'

import  { Container } from '../styles/components.js';

import Header from './header'
import Footer from './footer'
import Ambiance from './ambiance'
import Fond from './fond'

const Layout = ({ data, children }) => {
  const { frontmatter, ambiances } = data;
  const { noTexture, full, title } = frontmatter.childJavascriptFrontmatter.frontmatter;
  const ambiancesPage = ambiances && ambiances.nodes && ambiances.nodes.length && ambiances.nodes[0].data.images ? ambiances.nodes[0].data.images : null;
  const { allPrismicAmbiance } = useStaticQuery(graphql`
    query {
      allPrismicAmbiance (
        filter: {
          data: {
            ambiance_generale: { eq: true }
          }
        }
        limit: 1
      ) {
      nodes {
          ...ambiance
        }
      }
    }`
  );
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Fond>
          <Container>
            <Header />
          </Container>
          { !noTexture &&
            <Ambiance 
              full={full} 
              pageTitle={title} 
              ambiances={allPrismicAmbiance.nodes[0].data.images} 
              solo={true}
              ambiancesPage={ambiancesPage} />
          }
          <Container mb={[3,4]}>
            {children}
          </Container>
          <Footer />
        </Fond>
      </ThemeProvider>
      <GlobalStyle />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout