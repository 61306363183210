import { createGlobalStyle } from 'styled-components'
import 'typeface-open-sans'
import 'typeface-eb-garamond'
import reset from 'styled-reset'

const colors = {
  white: '#fff',
  black: '#000',
  placeholder: 'rgba(40,40,40,1)'
}

const breakpoints = [ '40em', '52em', '64em', '80em', '94em' ];

// px: 15, 17,18, 20, 25, 34
const fontSizes = ['1.5rem', '1.7rem', '1.8rem', '2rem', '2.5rem', '3.4rem']

const lineHeights = [
  1, 1.2
]
const fontWeights = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800
}

const fonts = {
  open: "Open Sans",
  garamond: "EB Garamond"
}

export const space = [ 
  0,
  '1rem',
  '2rem',
  '3rem',
  '6rem',
  '12rem',
  '24rem'
]

const borders = [
  0, '1px solid white', '1px solid black'
]

export const theme = {
  breakpoints,
  colors,
  space,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  borders
}

export const GlobalStyle = createGlobalStyle`
  ${reset}
  html, body {
    font-family: ${fonts.open};
    font-feature-settings: "liga", "kern";
    font-kerning: normal;
    font-size: 10px;
    background: none;
    color: white;
    @media (min-width: ${breakpoints[4]}) {
      font-size: 14px;
    }
  }
`
