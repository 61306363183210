import React from 'react'
import { graphql } from 'gatsby'
import { space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

import { Container, Flex, Box, Text, H1, Img } from '../styles/components'

const transitionName = 'ambiance'

const ombres = `
  width: 100%;
  position: absolute;
  left: 0;
  opacity: .6;
`
const OmbreHaut = styled.div`
  ${ombres};
  height: 2.3rem;
  background: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
  top: 0;
`
const OmbreBas = styled.div`
  ${ombres};
  height: 10rem;
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
  bottom: 0;
`

const TextureContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
`
const animationTime = 800; // in ms

const Texture = styled.div`
  ${space};
  width: 100%;
  position: absolute;
  opacity: 1;
  transition: ${animationTime}ms all linear;

  @media (max-width: ${themeGet('breakpoints.0', '40em')}) {
    transform: scale(1.4);
  }

  &.${transitionName}-enter {
    opacity: 0.5;
    filter: blur(50px);
  }
  &.${transitionName}-appear-active,
  &.${transitionName}-enter-done,
  &.${transitionName}-exit {
    opacity: 1;
    filter: blur(0);
    z-index: 2;
  }
  &.${transitionName}-exit-active,
  &.${transitionName}-appear {
    opacity: 0.01;
    filter: blur(50px);
  }
  &.${transitionName}-appear {
    opacity: 1;
    z-index: 1;
  }
`
Texture.defaultProps = {
  ...{...Texture.defaultProps, ...{
    mt: [0, "-10%"]
  }}
};

class Ambiance extends React.Component {
  animationRunning = false;

  constructor(props) {
    super(props);
    this.state = {
      visible: 0
    }
    this.changeTexture = this.changeTexture.bind(this);
  }
  playAmbiance(){
    this.animationRunning = setInterval(this.changeTexture, animationTime*8);
  }
  resetAmbiance(){
    clearInterval(this.animationRunning);
    this.playAmbiance();
  }
  changeTexture(visible=null){
    const { ambiances, ambiancesPage, solo } = this.props;
    const countAmbiances = ambiancesPage!==null ? ambiancesPage.length+(solo?0:ambiances.length) : ambiances.length;
    if (visible===null) {
      visible = this.state.visible+1===countAmbiances ? 0 : this.state.visible+1;
    }
    this.setState({visible:visible});
  }
  componentDidMount() {
    this.playAmbiance();
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.pageTitle!==prevProps.pageTitle){
      this.changeTexture((this.props.ambiancesPage!==null && !(this.props.ambiancesPage && prevProps.ambiancesPage && this.props.ambiancesPage.length === prevProps.ambiancesPage.length) )?0:null);
      this.resetAmbiance();
    }
  }

  render() {
    const { full, pageTitle, ambiances, ambiancesPage, solo } = this.props;
    var textures = [];
    if (ambiancesPage!==null) {
      if (solo) {
        textures = ambiancesPage;
      } else {
        textures = ambiancesPage.concat(ambiances);
      }
    } else {
      textures = ambiances;
    }
    const visible = this.state.visible >= textures.length ? 0 : this.state.visible;
    return (
      <Box bg="black" mb={3} position='relative'>
        <TextureContainer>
          <TransitionGroup>
            <CSSTransition
              classNames={transitionName}
              key={textures[visible].image.gatsbyImageData.images.fallback.src}
              unmountOnExit
              appear
              timeout={{
                enter: animationTime,
                exit: animationTime
              }}>
              <Texture>
                <Img image={textures[visible].image.gatsbyImageData} alt={`texture`} />
              </Texture>
            </CSSTransition>
          </TransitionGroup>
        </TextureContainer>
        <OmbreHaut />
        <OmbreBas />
        <Container position='relative'>
          <Flex justifyContent="center" px={2} alignItems="end">
            <Box width={[1,10/12]} mt={ full ? [5,null,6] : [4,null,5] } mb={[1, 2]}>
              { full ? 
                <Text fontSize={[2, "2.3rem"]} fontWeight="extraBold">Pénétrez dans le monde <br />
                de la <Text fontSize={['3.2rem',"4.8rem"]} fontFamily="garamond" as="span">lumière minérale</Text></Text> : 
                <H1 mb={[1,2]}>{pageTitle}</H1>
              }
            </Box>
          </Flex>
        </Container>
      </Box>
    )
  }
}

export default Ambiance

export const query = graphql`
  fragment ambiance on PrismicAmbiance {
    data {
      ambiance_generale
      images {
        image {
          gatsbyImageData(layout:FULL_WIDTH)
        }
      }
    }
  }
`

// fluid(maxWidth:1200, quality:80) {
//   ...GatsbyImageSharpFluid_withWebp
// }