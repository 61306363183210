import React from 'react'
import { Link } from 'gatsby'
import { Field as FormikField } from 'formik'
import styled from 'styled-components'
import { theme } from './theme.js'
import { 
  fontSize, 
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  width, 
  height, 
  position,
  top, 
  left, 
  bottom,
  right,
  color,
  order,
  alignSelf,
  space, 
  border,
  flex,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  display } from 'styled-system'
import Drop from 'react-dropdown'
import 'react-dropdown/style.css'
import { GatsbyImage as I } from "gatsby-plugin-image"


// export const fine = `&#x202F;`
export const fine = ` `

export const Box = styled.div`
  ${display}
  ${space}
  ${position}
  ${width}
  ${fontSize}
  ${color}
  ${display}
  ${flex}
  ${order}
  ${alignSelf}
  box-sizing: border-box;`

Box.defaultProps = {
  ...{...Box.defaultProps }
};

export const Image = styled(Box)``;
Image.defaultProps = {
  ...{...Image.defaultProps, ...{
    as: 'img'
  }}
};

export const Flex = styled(Box)`
  ${flexWrap}
  ${flexDirection}
  ${alignItems}
  ${justifyContent}
  ${height}
  ${top}
  ${left}
  ${bottom}
  ${right}
`;
Flex.defaultProps = {
  ...{...Flex.defaultProps, ...{
    display: 'flex'
  }}
};

export const Container = styled(Box)`
  max-width: 1024px;
  @media (min-width: ${theme.breakpoints[4]}) {
    max-width: 1300px;
  }
`

Container.defaultProps = {
  ...{...Container.defaultProps, ...{
    mx: 'auto',
    mt: 0,
    mb: 0
  }}
}


export const Br = styled.br`
  ${display};
`

export const Text = styled.p`
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${letterSpacing}
  ${space}
`

export const H1 = styled(Text)``

H1.defaultProps = {...{...H1.defaultProps, ...{
  as: 'h1',
  fontFamily: 'garamond',
  fontSize: 5,
  fontWeight: 'extraBold',
  mb: 2
}}};

export const H2 = styled(Text)``;
H2.defaultProps = {...{...H2.defaultProps, ...{
  as: 'h2',
  fontSize: 1,
  fontWeight: 'semiBold',
  mb: 2
}}};

export const P = (props) => {
  const defaults = {
    fontSize: 1,
    fontWeight: 'light',
    lineHeight: 1,
    mb: props.noMargin ? null : 2
  }
  return <Text {...{...defaults, ...props}}>{props.children}</Text>
}

export const Bold = styled.strong`
  font-weight: ${theme.fontWeights.bold};
`
export const Italic = styled.em`
  font-style: italic;
`
export const Img = styled(I)`
  ${position}
  ${space}
  ${width}
  ${height}
`

export const NavLink = styled(Link)`
  ${fontSize};
  ${space};
  ${color};
  font-family: ${theme.fonts.garamond};
  font-weight: ${theme.fontWeights.extraBold};
  text-decoration: none;
  display: block;
  line-height: 2.7rem;
  &:hover {
    text-decoration: underline;
  }
`

NavLink.defaultProps = {
  ...{...NavLink.defaultProps, ...{
    color:'inherit'
  }}
};

export const HeaderLink = styled(NavLink)``;
HeaderLink.defaultProps = {...{...HeaderLink.defaultProps, ...{fontSize:[1,3,4]}}};

const LinkStyles = `
  color: inherit;
  font-size: ${theme.fontSizes[2]};
  font-weight: ${theme.fontWeights.bold};
  text-decoration: none;
  font-family: ${theme.fonts.garamond};
  &:hover {
    text-decoration: underline;
  }
`

export const BodyLink = styled(Link)`
  ${LinkStyles}
`

export const buttonHover = `
  background: rgba(20,20,20,.8);
  .stroke {
    stroke: white;
  }
`
export const Button = styled(BodyLink)`
  ${width};
  ${border};
  ${space};
  ${color};
  ${LinkStyles};
  background: none;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    ${buttonHover}
  }
`

Button.defaultProps = {
  ...{...Button.defaults, ...{
    px: 3,
    py: 1,
    border: 1,
    color: 'white'
  }}
};

const field = `
  background: rgba(0,0,0,.3);
  color: white;
  box-sizing: border-box;
  &::-webkit-input-placeholder {
    color: white;
    font-style: italic;
  }
`

export const Input = styled.input`
  ${space};
  ${width};
  ${border};
  ${fontSize};
  ${field};
`
const inputProps = {
  px: 3,
  py: 1,
  width: 1,
  border: 0,
  fontSize: 1
}
Input.defaultProps = {...{...Input.defaults, ...inputProps}};

export const Field = styled(FormikField)`
  ${space};
  ${width};
  ${border};
  ${fontSize};
  ${field};
`
Field.defaultProps = {...{
  ...Field.defaults, ...{
    ...inputProps
  }}
};

export const Dropdown = styled(Drop)`
  .Dropdown-control {
    background: none;
    color: white;
    border: 0;
    padding-left: 0;
  }
  .Dropdown-menu {
    margin-left: -${theme.space[3]};
    background: none;
    border: 0;
  }
  .Dropdown-option {
    background: rgba(0,0,0,.9);
    color: white;
    padding: ${theme.space[1]} ${theme.space[3]};
    margin-bottom: 1px;
    &:hover, &.is-selected {
      background: rgba(10,10,10,1);
      color: inherit;
      text-decoration: underline;
    }
  }
`
